import gql from 'graphql-tag'

/**
 *
 * Auth
 *
 */

export const SIGN_IN = gql`
  query signIn ($email: String! $password: String! ){
    sign_in(email: $email password: $password ){
      token
      firebase_token
    }
  }
`

export const CURRENT_USER = gql`
  query {
    current_user {
      id
      name
      email
      role
    }
  }
`

export const IMAGES = gql`
  query($where: images_bool_exp) {
    images( where: $where, order_by: { captured_at: desc }) {
      captured_at
      file_path
      type
    }
  }
`

/**
 *
 * Dashboard
 *
 */

export const PADDOCKS = gql`
  query($where: paddocks_bool_exp) {
    paddocks ( where: $where, order_by: { code: asc }){
      id
      name
      code
    }
  }
`


export const SATELLITES_DATA_ADVANCED_INDICATORS = gql`
 query ($where: satellites_data_advanced_bool_exp){
  satellites_data_advanced(
    where: $where
    order_by: {
      captured_at: asc
    }
  ){
    captured_at
    em
    fdn
    pb
    ms_adjusted
    type
    paddock{
      id
      name
      code
    }
  }
 }
`

export const SATELLITES_DATA_ADVANCED_INDICATORS_AVG = gql`
 query ($where: satellites_data_advanced_bool_exp){
  satellites_data_advanced_aggregate( where: $where ){
  	aggregate{
      max{
        em
        pb
        fdn
        ms_adjusted_area
      }
      min{
        em
        pb
        fdn
        ms_adjusted_area
      }
      avg{
        em
        pb
        fdn
        ms_adjusted_area
      }
    }
  }
 }
`

export const SATELLITES_DATA_ADVANCED_INDICATORS_AVG_MS = gql`
 query ($wherePaddocks: paddocks_bool_exp, $whereSatellitesData: satellites_data_advanced_bool_exp){
  paddocks( where: $wherePaddocks, order_by: { code: asc } ){
  	id
    code
    name
    satellites_data_advanced_aggregate( where: $whereSatellitesData){
      aggregate{
        avg{
          em
          pb
          fdn
          ms_adjusted
          ms_adjusted_area
        }
      }
    }
  }
 }
`

export const PADDOCKS_SATELLITES_DATA_ADVANCED_INDICATORS_AVG = gql`
 query ($wherePaddocks: paddocks_bool_exp, $whereSatellitesData: satellites_data_advanced_bool_exp){
  paddocks( where: $wherePaddocks, order_by: { code: asc } ){
  	id
    code
    name
    satellites_data_advanced_aggregate( where: $whereSatellitesData ){
      aggregate{
        avg{
          ms_adjusted_area
        }
      }
    }
  }
 }
`

export const SATELLITES_DATA_ADVANCED_PAGINATE = gql`
 query (
   $limit: Int,
   $offset: Int
   $where: satellites_data_advanced_bool_exp,
   $orderBy: [satellites_data_advanced_order_by!],
  ){
  list: satellites_data_advanced(
    limit: $limit
    offset: $offset
    where: $where
    order_by: $orderBy
  ){
    id
    captured_at
    em
    fdn
    pb
    ms_adjusted
    type
    paddock {
      id
      name
      code
      module {
        id
        code
        name
        farm {
          id
          name
        }
      }
    }
  }

  count: satellites_data_advanced_aggregate(where: $where){
    aggregate{
      count
    }
  }
 }
`

export const BATCH_TRANSACTIONS_BY_MONTH = gql`
 query ($where: batch_transactions_bool_exp){
  batch_transactions( where: $where, order_by: { event_date: asc } ){
    event_date
    expected_consumption
    number_of_heads
    module_capacity
    type
    interval
    day_occupation
  }
 }
`

export const BATCHES = gql`
 query ($where: batches_bool_exp){
  batches ( where: $where, order_by: { code: desc } ){
    id
  }
 }
`