import { SIGN_UP } from "@/graphql/mutations"
import { SIGN_IN, CURRENT_USER } from "@/graphql/queries"
import { signIn } from "@/utils/firebase"
import { apolloClient, onLogin, onLogout } from "@/vue-apollo"

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem('apollo-token'),
    firebaseToken: localStorage.getItem('firebase-token'),
    user: {},
    authStatus: false
  },

  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    user: state => state.user,
    role: state => state.user.role,
    isManager: state => state.user.role === 'manager',
    isConsultant: state => state.user.role === 'consultant'
  },

  mutations: {
    SET_TOKEN (state, payload) {
      state.token = payload.token
      state.firebaseToken = payload.firebaseToken
      localStorage.setItem('firebase-token', payload.firebaseToken)
    },
    LOGIN_USER (state, user) {
      state.authStatus = true
      state.user = { ...user }
    },
    LOGOUT_USER (state) {
      state.authStatus = ''
      state.firebaseToken = ''
      state.token = '' && localStorage.removeItem('apollo-token') && localStorage.removeItem('firebase-token')
    }
  },

  actions: {
    async register (store, authDetails) {
      const { data } = await apolloClient.mutate({ mutation: SIGN_UP, variables: { ...authDetails } })
      return data.sign_up
    },

    async login ({ commit, dispatch }, authDetails) {
      const { data } = await apolloClient.query({ query: SIGN_IN, variables: { ...authDetails } })
      const token = JSON.stringify(data.sign_in.token)
      const firebaseToken = JSON.stringify(data.sign_in.firebase_token)
      onLogin(apolloClient, token)
      signIn(firebaseToken)
      commit('SET_TOKEN', { token, firebaseToken })
      dispatch('setUser')
      return token
    },

    async setUser ({ commit }) {
      const { data } = await apolloClient.query({ query: CURRENT_USER })
      commit('LOGIN_USER', data.current_user[0])
    },

    async logOut ({ commit }) {
      commit('LOGOUT_USER')
      onLogout(apolloClient)
    }
  }
}
