import 'animate.css/animate.css';
import './design/index.scss';
import './plugins';
import { VueMaskDirective } from 'v-mask';
import Vue from 'vue';
import VueHtml2Canvas from 'vue-html2canvas';
import VueSilentbox from 'vue-silentbox'

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { createProvider } from './vue-apollo';

Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false;
Vue.use(VueHtml2Canvas)
Vue.use(VueSilentbox);

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
