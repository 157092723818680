import store from '@/store'
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Layout Auth',
    component: () => import('./layouts/Auth.vue'),
    redirect: { name: 'Dashboard' },
    children: [
      {
        path: '/sign-in',
        alias: '/autenticacao',
        name: 'Sign in',
        component: () => import('./views/auth/SignIn.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Layout Main',
    component: () => import('./layouts/Main.vue'),
    redirect: { name: 'Dashboard' },
    children: [
      {
        path: '',
        name: 'Dashboard',
        meta: { authRequired: true },
        component: () => import('./views/dashboard/Dashboard.vue'),
      },
      {
        path: '/perfil',
        name: 'perfilAlimentar',
        meta: { authRequired: true },
        component: () => import('./views/dashboard/foodProfile.vue'),
      },
      {
        path: '/satelite',
        alias: '/imagem_satelite',
        name: 'Satelite',
        meta: { authRequired: true },
        component: () => import('./views/satelite/ImagesViewer.vue'),
      },
      {
        path: '/map',
        alias: '/Mapa',
        name: 'Mapa',
        meta: { authRequired: true },
        component: () => import('./views/satelite/SateliteMap.vue'),
      },
      {
        path: '/calculator',
        alias: '/calculadora',
        name: 'Calculator',
        meta: { authRequired: true },
        component: () => import('./views/calculator/Calculator.vue'),
      },
      {
        path: '/calculator/:id',
        alias: '/calculadora/:id',
        name: 'Calculator Batch',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/calculator/Calculator.vue'),
      },
      {
        path: '/batches',
        alias: '/lotes',
        name: 'Batches',
        meta: { authRequired: true },
        component: () => import('./views/batches/Batches.vue'),
      },
      {
        path: '/batches/new',
        alias: '/lotes/novo',
        name: 'Batches New',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/batches/Register.vue'),
      },
      {
        path: '/batches/:id/edit',
        alias: '/lotes/:id/editar',
        name: 'Batches Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/batches/Register.vue'),
      },
      {
        path: '/batches/:id',
        alias: '/lotes/:id',
        name: 'Batch Livestock',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/batches/Livestock.vue'),
      },
      {
        path: '/satellites',
        alias: '/medicoes',
        name: 'Satellites',
        meta: { authRequired: true },
        component: () => import('./views/admin/satellites/List.vue'),
      },
      {
        path: '/satellites/:id',
        alias: '/medicoes/:id',
        name: 'Satellites Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/admin/satellites/Register.vue'),
      },
      {
        path: '/images',
        alias: '/imagens',
        name: 'Imagens',
        meta: { authRequired: true },
        component: () => import('./views/admin/images/List.vue'),
      },
      {
        path: '/cultivars',
        alias: '/cultivares',
        name: 'Cultivars',
        meta: { authRequired: true },
        component: () => import('./views/admin/cultivars/List.vue'),
      },
      {
        path: '/cultivars/:id',
        alias: '/cultivares/:id',
        name: 'Cultivars Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/admin/cultivars/Register.vue'),
      },
      {
        path: '/params',
        alias: '/parametros',
        name: 'Parâmetros',
        meta: { authRequired: true },
        component: () => import('./views/admin/params/List.vue'),
      },
      {
        path: '/params/:id',
        alias: '/parametros/:id',
        name: 'Parametros Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/admin/params/Register.vue'),
      },
      {
        path: '/params/animal/:geneticPattern/:categoryAnimal',
        alias: '/parametros/animal/:geneticPattern/:categoryAnimal',
        name: 'Parametros Animal Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/admin/params/RegisterAnimal.vue'),
      },
      {
        path: '/factors',
        alias: '/fatores',
        name: 'Fatores de ajuste',
        meta: { authRequired: true },
        component: () => import('./views/admin/factors/List.vue'),
      },
      {
        path: '/customers',
        alias: '/clientes',
        name: 'Customers',
        meta: { authRequired: true },
        component: () => import('./views/admin/customers/List.vue'),
      },
      {
        path: '/customers/:id',
        alias: '/clientes/:id',
        name: 'Customers Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/admin/customers/Register.vue'),
      },
      {
        path: '/users',
        alias: '/usuarios',
        name: 'Users',
        meta: { authRequired: true },
        component: () => import('./views/admin/users/List.vue'),
      },
      {
        path: '/users/:id',
        alias: '/usuarios/:id',
        name: 'Users Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/admin/users/Register.vue'),
      },
      {
        path: '/farms',
        alias: '/fazendas',
        name: 'Farms',
        meta: { authRequired: true },
        component: () => import('./views/farms/List.vue'),
      },
      {
        path: '/farms/:id',
        alias: '/fazendas/:id',
        name: 'Farms Edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('./views/farms/Register.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


// Global Route Guards

router.beforeEach((to, from, next) => {
  // Check if the user is logged in

  const isUserLoggedIn = store.getters['auth/isAuthenticated']

  if (to.matched.some(record => record.meta.authRequired)) {
    if (!isUserLoggedIn) {
      store.dispatch('auth/logOut')
      next({
        path: '/autenticacao',
        query: { redirect: to.fullPath }
      })
    } else {
      store.dispatch('auth/setUser')
      next()
    }
  } else {
    next()
  }
  store.dispatch('analytics/setCurrentScreen', { currentScreen: to.fullPath, screenName: to.name })
})

export default router;
