import { apolloClient } from "@/vue-apollo"
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'

import { PADDOCKS } from "../../graphql/queries"

export default {
  namespaced: true,

  state: {
    filter: {},
    paddocks: []
  },

  mutations: {
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    SET_FILTER (state, filter) {
      const newFilter = cloneDeep(filter)
      newFilter.startAt = newFilter.startAt ? dayjs(newFilter.startAt).toISOString() : null
      newFilter.endAt = newFilter.endAt ? dayjs(newFilter.endAt).toISOString() : null
      state.filter = newFilter
    },
    SET_MEASURE_DATE_FILTER (state, filter) {
      const newFilter = cloneDeep(filter)
      let date = []
      if (newFilter && newFilter.date) {
        date = newFilter.date.split('-')
        newFilter.date = newFilter.date ? dayjs(date[2]+date[1]+date[0]) : null
        newFilter.date = newFilter.date? dayjs(newFilter.date).toISOString() : null
        state.filter = newFilter
      }
    },
    SET_MEASURE_TYPE_FILTER (state, filter) {
      const newFilter = cloneDeep(filter)
      state.filter = newFilter
    },
    SET_PADDOCKS (state, paddocks) {
      state.paddocks = cloneDeep(paddocks)
    },
  },

  actions: {
    async changeFilter ({ commit, dispatch }, filter) {
      await dispatch('fetchPaddocks', filter)
      commit('SET_FILTER', filter)
    },

    async changeMeasureDateFilter ({ commit }, filter) {
      commit('SET_MEASURE_DATE_FILTER', filter)
    },

    async changeMeasureTypeFilter ({ commit }, filter) {
      commit('SET_MEASURE_TYPE_FILTER', filter)
    },

    async changeImageFilter ({ commit }, filter) {
      commit('SET_FILTER', filter)
    },

    async fetchPaddocks ({ commit }, filter) {
      const moduleId = filter.moduleId
      if(!moduleId) return

      const filterParsed = {}
      filterParsed.where = { _or: [ { module_id : { _eq: moduleId } }, {ref_module_id : { _eq: moduleId }} ] }

      const res = await apolloClient.query({ query: PADDOCKS, variables: filterParsed })
      commit('SET_PADDOCKS', res.data.paddocks)
    }
  }
}
