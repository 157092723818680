import store from '@/store';
import { from } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import Swal from 'sweetalert2';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql';

const authLink = setContext(async (_, { headers }) => {
  // Use your async token function here:
  const token = localStorage.getItem(AUTH_TOKEN)
  // Return the headers to the context so httpLink can read them
  const headerParsed = {
    headers: {
      ...headers,

    }
  }

  if (token) {
    // headerParsed.headers.authorization = token
  } else {
    headerParsed.headers['x-hasura-role'] = 'anonymous'
  }

  return headerParsed
})
const errorHandler = onError((e) => {
  const error = e.graphQLErrors[0]
  console.log({error})
  if (error) {
    if (error.extensions.code === 'invalid-jwt') {
      Swal.fire({
        icon: 'error',
        text: 'Login expirado! Favor entrar novamente com suas credenciais.',
        position: 'top-end',
        toast: true,
        showConfirmButton: false,
        timer: 5000,
      })
      store.dispatch('auth/logOut')
        .then(() => this.$router.push('/sign-in'))
    } else if (error.extensions.code !== 'business') {
      Swal.fire({
        icon: 'error',
        text: 'Ocorreu um erro interno, se persistir contate o suporte.',
        position: 'top-end',
        toast: true,
        showConfirmButton: false,
        timer: 5000,
      })
    }
  }
})

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // link: ApolloLink.from([authLink])
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  link: from([authLink, errorHandler]),
  // httpLinkOptions: {
  //   headers: {
  //     'x-hasura-admin-secret': '6QZ5UyGdM2g5i7AW4CcUd8HYUzTgMMlMEZxAztu6VILu9b8Guh5FfONpKCzMCZ2s',
  //   },
  // },

  // Override default cache
  // cache: false,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ({
  //   'x-hasura-admin-secret': '6QZ5UyGdM2g5i7AW4CcUd8HYUzTgMMlMEZxAztu6VILu9b8Guh5FfONpKCzMCZ2s'
  // })

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
  // ...options
})
apolloClient.wsClient = wsClient

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  // const { apolloClient, wsClient } = createApolloClient({
  //   ...defaultOptions,
  //   ...options,
  // });
  // apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    }
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token.replace(/"/g, ''));
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
