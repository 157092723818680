import { app } from '@/utils/firebase'
import { getAnalytics, logEvent, setUserId, setUserProperties, setCurrentScreen } from "firebase/analytics"

export default {
  namespaced: true,

  state: {
    analytics: getAnalytics(app)
  },

  mutations: {
    trackEvent: (state, payload) => {
      logEvent(state.analytics, payload.event, payload.params)
    },
    setUserId: (state, payload) => {
      setUserId(state.analytics, payload.userId)
    },
    setUserProperties: (state, payload) => {
      setUserProperties(state.analytics, payload.properties)
    },
    setCurrentScreen: (state, payload) => {
      setCurrentScreen(state.analytics, payload.currentScreen, payload.screenName)
    }
  },

  actions: {
    trackEvent: ({ commit }, payload) => {
      commit('trackEvent', payload)
    },
    setUserId: ({ commit }, payload) => {
      commit('setUserId', payload)
    },
    setUserProperties: ({ commit }, payload) => {
      commit('setUserProperties', payload)
    },
    setCurrentScreen: ({ commit }, payload) => {
      commit('setCurrentScreen', payload)
    },
  }
}