import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import dayjs from 'dayjs';
import parsePhoneNumber from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import round from 'lodash/round'
import Vue from 'vue';

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Quinta', 'Sábado'],
  dayOfWeekNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  // timezone: 0,
});

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('uppercase', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter('lowercase', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter('cpf', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
});

Vue.filter('phone', function (value) {
  if (!value) return '';
  value = value.toString();
  const phoneNumber = parsePhoneNumber(value, 'BR');
  return phoneNumber ? phoneNumber.formatNational() : value;
});

Vue.filter('number:decimals', function (value, qtd = 2, suffix = '') {
  if (value === '---') return value;
  if (!isNumber(value)) return '';
  return round(value, qtd).toLocaleString(undefined, { minimumFractionDigits: qtd }) + suffix
});

Vue.filter('number:noRound', function (value, qtd= 0) {
  if (value === '---') return value;
  if (!isNumber(value)) return '';
  let number = value.toFixed(qtd).split('.');
  number[0] = number[0].split(/(?=(?:...)*$)/).join('.');
  return number.join(',')
});

Vue.filter('number', function (value, suffix = '') {
  if (!isNumber(value)) return '';
  return value.toLocaleString(undefined) + suffix
})

Vue.filter('percent', function (value, qtd = 2) {
  if (!isNumber(value)) return '';
  return round(value, qtd).toLocaleString(undefined, { minimumFractionDigits: qtd }) + '%'
})

Vue.filter('date', function (value, format = 'DD/MM/YYYY HH:mm') {
  if (!value) return '';
  return dayjs(value).format(format)
})

Vue.filter('shortDate', function (value, format = 'DD/MM/YYYY') {
  if (!value) return '';
  return dayjs(value).format(format)
})

Vue.filter('defaultEmpty', function (value, emptyString = '---') {
  if (!isEmpty(value)) return value
  return emptyString
})
