import Vue from 'vue';
import Vuex from 'vuex';

import analytics from './modules/analytics';
import auth from './modules/auth';
import dashboard from './modules/dashboard';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    analytics,
    dashboard,
  }
});
