import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import en from 'vuetify/es5/locale/en';
import pt from 'vuetify/es5/locale/pt';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt, en },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
